import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import BlogCard from "../components/blog-card";
import SecondaryHero from "../components/secondary-hero";

const BlogPostTemplate = ({ data: { post, site, otherPosts } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blog/${post.slug}`,
					id: `${siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | Friends of Eastbourne Hospital`}
				description={post.blogSeo.metaDescription}
				openGraph={{
					url: `${siteUrl}/blog/${post.slug}`,
					title: `${post.title} | Friends of Eastbourne Hospital`,
					description: post.blogSeo.metaDescription,
					type: "article",
					images: [
						{
							url: `${post.blogFields.featuredImage.localFile.publicURL}`,
							width: `${post.blogFields.featuredImage.localFile.childImageSharp.original.width}`,
							height: `${post.blogFields.featuredImage.localFile.childImageSharp.original.height}`,
							alt: `${post.blogFields.featuredImage.altText}`,
						},
					],
				}}
			/>
			<section>
				<SecondaryHero title={post.title} />
				<Container fluid className="px-0 mx-0 position-relative">
					<Row
						className="align-items-center justify-content-center
					 gx-0"
					>
						<Col lg={9} xl={7}>
							<div className="position-relative post-page-image ">
								<GatsbyImage
									class="w-100   "
									image={
										post.blogFields.featuredImage?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={post.blogFields.featuredImage?.localFile.altText}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className="  py-5">
					<Row className="justify-content-center">
						<Col>
							<div className="blog-content px-4">{parse(post.content)}</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className=" pb-7">
					<Row className="justify-content-center">
						{" "}
						{otherPosts.nodes
							.filter((item, index) => index < 3)
							.map((post) => (
								<BlogCard
									boxShadow="0px 3px 50px #D1D1D1"
									title={post.title}
									excerpt={parse(post.excerpt)}
									uri={post.slug}
									image={
										post.blogFields.featuredImage?.localFile.childImageSharp
											.gatsbyImageData
									}
									imageAlt={post.blogFields.featuredImage.altText}
								/>
							))}
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			slug
			content
			title
			blogSeo {
				metaDescription
			}
			dateGmt(formatString: "MMMM DD, YYYY")
			blogFields {
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 50
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
		otherPosts: allWpPost(
			filter: { id: { ne: $id }, title: { ne: "Website Images" } }
			sort: { fields: dateGmt, order: DESC }
		) {
			nodes {
				id
				title
				excerpt
				slug
				blogFields {
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 50
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
